<template>
  <div>
    <!-- Alert: No item found -->
    <b-alert variant="danger" :show="userHolidayData === undefined">
      <h4 class="alert-heading">Error fetching user data</h4>
      <div class="alert-body">
        No user found with this user id. Check
        <b-link class="alert-link" :to="{ name: 'holidays' }">
          referral List
        </b-link>
      </div>
    </b-alert>

    <template v-if="userHolidayData">
      <!-- First Row -->
      <b-row>
        <b-col cols="12" xl="12" lg="12" md="12">
          <holiday-view-info
            :user-data="userHolidayData"
            :custom-fields="customFields"
          />
        </b-col>
      </b-row>
    </template>
  </div>
</template>

<script>
import store from "@/store";
import router from "@/router";
import { ref, onUnmounted } from "@vue/composition-api";
import {
  BRow,
  BCol,
  BAlert,
  BLink,
  BTabs,
  BTab,
  BCard,
  BCardText,
  BDropdown,
  BDropdownItem,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  VBTooltip,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { VueGoodTable } from "vue-good-table";
import holidayStoreModule from "../holidayStoreModule";
import HolidayViewInfo from "./HolidayViewInfo.vue";
export default {
  components: {
    BRow,
    BCol,
    BAlert,
    BLink,
    BTabs,
    BTab,
    BCard,
    BCardText,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    HolidayViewInfo,
    VueGoodTable,
  },
  directives: {
    Ripple,
    "b-tooltip": VBTooltip,
  },
  setup() {
    const userHolidayData = ref(null);
    const holiday = ref({
      name: "",
      id: "",
    });
    const USER_APP_STORE_MODULE_NAME = "app-holidays";

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
      store.registerModule(USER_APP_STORE_MODULE_NAME, holidayStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME))
        store.unregisterModule(USER_APP_STORE_MODULE_NAME);
    });

    store
      .dispatch("app-holidays/fetchHoliday", router.currentRoute.params.id)
      .then((response) => {
        userHolidayData.value = response.data.data;
        holiday.value.name = userHolidayData.value.name;
        holiday.value.id = userHolidayData.value.hashid;
      })
      .catch((error) => {
        if (error.response.status === 404) {
          userHolidayData.value = undefined;
        }
      });
    return {
      userHolidayData,
      holiday,
    };
  },
  data() {
    return {
      pageLength: 3,
      holidayChangeFlag: true,
      pageLengthEmployee: 3,
      dir: false,
      isAddNewInterviewSidebarActive: false,
      searchTerm: "",
      customFields: [],
    };
  },
  created() {
    this.getCustomFields();
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true;
        return this.dir;
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false;
      return this.dir;
    },
  },
  methods: {
    viewInterview(id) {
      this.$router.push(`/view/interview/${id}`).catch(() => {});
    },
    assignInterview() {
      console.log("assigning interview...");
      this.isAddNewInterviewSidebarActive = true;
    },
    refetchHolidaysData() {
      console.log("refetching started..");
    },
    getCustomFields() {
      const self = this;
      self.$store
        .dispatch("app-holidays/getCustomFields")
        .then((res) => {
          self.customFields = res.data.data;
        })
        .catch((error) => {
          self.$toast(
            {
              component: ToastificationContent,
              props: {
                title: "Oops! Custom Fields Getting Failed",
                icon: "BellIcon",
                variant: "danger",
              },
            });
        });
    },
    containsKey(obj, key) {
      return Object.keys(obj).includes(key);
    },
  },
};
</script>

<style lang="scss" >
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>

<style lang="scss" scoped>
.add-btn {
  border: solid 1px;
  width: 70%;
}
.interview-pass {
  color: green;
}
.interview-fail {
  color: indianred;
}
</style>